body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #003300;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: grid;
  min-width: 100%;

  grid-template-columns: 33.334% 66.667%;
}

.next-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border: 1px solid white;
  border-radius: 0.4em;
  height: 8em;
  width: 5.5em;
  margin: 0.2em;
  pointer-events: none;
  cursor: pointer;
}

.card-image {
  height: 100%;
  width: 100%;
}

.card-board-header {
  color: yellow;
  margin-left: 8em;
}

.card-occupied {
  border: none;
}

.row-hand-description {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  min-width: 1em;
}

.game-rules {
  position: relative;
  top: 12em;
  left: 8em;
  text-decoration: none;
  color: inherit;
}
.col-hand-description {
  height: 2em;
  font-size: small;
  text-align: center;
}

.board-summary {
  display: flex;
  margin-left: 2rem;
  margin-top: 1rem;
  position: relative;
}

.board-summary-label {
  flex-grow: 1;
  margin-right: 0.5rem;
  margin-left: 2rem;
}
